/* AddCategory.css */

.add-category-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
  /* font-family: 'Your Custom Font', sans-serif; */
}

/* Style for the placeholder text */
input::placeholder,
textarea::placeholder {
  /* font-family: 'Your Custom Font', sans-serif; */
}
.createcatbtn-group {
  margin-top: 15px;
}

.createcatbtn {
  background-color: #009595;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.createcatbtn:hover {
  background-color: #45a049;
}
.outerBox {
  /* border: 1px solid #d4d4d4;
  border-radius: 4px; */
}
.outerBox > * {
  padding: 0px 10px;
}

.innerBox {
  /* height: 80px; */
  padding: 10px;
  margin: 10px 0px;
  border-width: thin;
  border-radius: 4px;
  border-style: solid;
  border-color: transparent;
  transition: border 0.4s ease;
  height: 40px;
}
.innerBox:hover {
  border-color: #d4d4d4;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}
.cattitle {
}
.catHeader {
  font-weight: 700;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.innerBox:hover .iconBtn {
  display: block;
}

.iconBtn {
  opacity: 0;
  visibility: hidden;
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
}
.table tr:hover .iconBtn{
  display: flex;
  opacity: 1;
  visibility: visible;
}
.iconbutton {
  width: 40px;
  text-align: center;
  height: 40px;
  transition: all 0.3s ease-in;
  background: #fff;
  border: 1px solid  #45a049;
  border-radius: 100px;
  color: #000;
  padding: 0;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

}
.iconbutton:hover{
  background-color: #008080;
  color: #fff;
  border-color: #008080;
}
.card-header-btn{
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  
}
.card:hover .card-header-btn {
  opacity: 1;
  visibility: visible;
}
.custom-modal {
  /* z-index: -10; */
  opacity: 0; /* Set initial opacity to 0 */
  transition: opacity 0.3s ease-in-out; /* Add a transition for opacity */
}

.custom-modal.show {
  opacity: 1; /* When the modal has the 'show' class, set opacity to 1 */
  z-index: 10;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-background {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  opacity: 0; /* Set initial opacity to 0 */
  transform: translateY(-20px); /* Move the modal up initially */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Add transitions for opacity and transform */
}

.custom-modal.show .modal-background {
  opacity: 1; /* When the modal has the 'show' class, set opacity to 1 */
  transform: translateY(0); /* Move the modal back to its original position */
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
