* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
  color: inherit;
}
.dropdown-toggle::after {
  display: none;
}
