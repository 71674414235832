.sidelink {
  /* background: red; */
  border-radius: 5px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  color: #fff;
  height: 48px;
  width: 100%;
}
.sidelink:hover {
  text-decoration: none;
  background-color: #fff;
  color: rgb(0, 167, 111);
}
.sidelink.active {
  background-color: #fff;
  color: rgb(0, 167, 111);
}
.svgicon {
  width: 40px;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
