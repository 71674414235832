
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.2)
}
::-webkit-scrollbar-track {
  background-color: #008080;
  border-radius: 4px
}
.btn-success{
  --bs-btn-border-color: #008080;
  --bs-btn-bg: #008080;
}
body{
  font-size: .85rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
button:focus-visible, a:focus-visible, button:focus-within{
  border: 0;
  outline: none;
  box-shadow: none;
}
.warpper {
  display: grid;
  height: 100vh;
  grid-template-areas:
      "app-sidebar-area app-header-area"
      "app-sidebar-area app-main-area";
  grid-template-columns: 16rem 1fr;
  grid-template-rows: 3.5rem 1fr;
}

.asidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
  display: grid;
  grid-area: app-sidebar-area;
  grid-template-areas:
      "app-logo-area"
      "app-sidenav-area";
  grid-template-rows: 3.5rem 1fr;
  height: 100%;
  background: #008080;
  color: #fff;
  padding: 0 1rem 1rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.app-header {
  display: grid;
  grid-area: app-header-area;
  grid-template-areas: "app-header-left-menu-area app-header-right-menu-area";
  align-content: center;
  background-color: #008080;
  color: #fff;
}
.main-container {
  display: grid;
  grid-area: app-main-area;
  padding: 0rem  0rem;
  align-content: start;
  overflow-y: auto;
  position: relative;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.shadow.Sidebaractive {
  width: 70px;
}

.sidelink .link-title {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}
.app-header-left-menu {
  grid-area: app-header-left-menu-area;
  justify-self: start;
}
.app-header-right-menu {
  grid-area: app-header-right-menu-area;
  justify-self: end;
}
.Sidebaractive .sidelink .link-title {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.app-sidenav {
  display: grid;
  grid-area: app-sidenav-area;
  grid-template-areas: ".";
}
.app-logo {
  display: grid;
  grid-area: app-logo-area;
  align-content: center;
  padding: 0 1rem;
}

.main-container.active {
  margin-left: 70px;
  width: calc(100% - 70px);
}
.line-3{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}