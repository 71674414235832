@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

h1 {
  font-weight: bold;
  margin: 0;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}
.logincontainer {
  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  min-height: 380px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.loginform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loginform > * {
  align-items: center;
  margin: 10px;
}
/* input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  display: block;
  width: 250px;
} */
input {
  width: 250px;
  border: none;
  border-bottom: 1px solid lightgray;
}

input:focus {
  outline: none;
}
.loginbutton {
  border-radius: 20px !important;
  border: 1px solid #008080;
  background-color: #008080;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  text-align: center;
}

.loginbutton:active {
  transform: scale(0.95);
}

.loginbutton:focus {
  outline: none;
}

.loginbutton.ghost {
  background-color: transparent;
  border-color: #ffffff;
}
